import React from 'react';
import Layout from '../components/layout/Layout';
// import LabelText from '../components/LabelText';
import CustomerCard from '../components/CustomerCard';
import FAQ from '../components/FAQ'
import { Helmet } from "react-helmet"
import './services.css'
import { Link } from "gatsby"

import {
  // AcademicCapIcon,
  BadgeCheckIcon,
  // CashIcon,
  ClockIcon,
  // ReceiptRefundIcon,
  UsersIcon,
  AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon
} from '@heroicons/react/outline'
import customerData from '../data/customer-data';

import { CheckIcon } from '@heroicons/react/solid'

const steps = [
  { id: '01', name: 'Assessment', description: 'Brainstorm & define your cloud needs. Based on the requirements a proposal with the needed tools, infrastructure, architecture & technology on the cloud platforms like AWS, GCP etc. can be defined', href: '#', status: 'current' },
  { id: '02', name: 'Tech Architecture', description: 'We break down the problem into tangible & decoupled building blocks/components/services. This lets the team to work independently.', href: '#', status: 'current' },
  { id: '03', name: 'Development using Agile', description: 'Sprint planning, team implementation, stand ups, weekly demos and reviews make sure everyone is on the same page and can raise their concerns.', href: '#', status: 'current' },
  { id: '04', name: 'Code reviews', description: 'Code reviews help standardize, enforce code quality, helps remove bugs, code smells', href: '#', status: 'current' },
  /* { id: '05', name: 'Maintenance', href: '#', status: 'current' }, */
]

const actions = [
  {
    title: 'Cloud Native services',
    href: '/',
    icon: ClockIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We provide services to build your entire product in native cloud such as AWS/GCP. Micro-services, Containerization, CI/CD tooling and automate services deployment, orchestration, observability, alerting, monitoring on the cloud",
  },
  {
    title: 'DevOps Services',
    href: '/',
    icon: BadgeCheckIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "DevOps practices increase developer productivity, faster to market iteratively and confidently. Automate infrastructure, orchestration, observability, alerting, monitoring, security stacks on the cloud using Infrastructure as Code (IaC)",
  },
  {
    title: 'Cloud Migration',
    href: '/',
    icon: UsersIcon,
    iconForeground: 'text-[#fff]',
    iconBackground: 'bg-[#6366f1]',
    cardText: "We provide cloud migration services, review your existing architecture and implement cloud-ready features, migrate to cloud",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Offerings = () => {
  return (
    <>
    <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl text-center pb-4">
            <span className="block text-indigo-600 xl:inline">Offerings</span>
          </h1>
    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-3 sm:gap-px">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:bg-indigo-100'
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white'
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" style={{width: '50px', height: '50px'}}/>
            </span>
          </div>
          <div className="mt-8">
            <h3 className="text-lg font-medium">
              <a href={action.href} className="focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
              </a>
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              {action.cardText}
            </p>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}

const Steps = () => {
  return (
    <>
    <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl text-center pb-16">
            <span className="block text-indigo-600 xl:inline">How we implement</span>{' '}
            <span className="block xl:inline">cloud services using agile</span>
          </h1>
    <nav aria-label="Progress">
      <ol className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex" style={{flexDirection: 'column', padding: '1rem'}}>
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <>
              <a href={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </a>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p className="ml-4 text-sm font-medium text-blue-600">{step.description}</p>
                </div>
              </>
            ) : (
                  <a href={step.href} className="group flex items-center">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                        <span className="text-gray-600 group-hover:text-gray-900">{step.id}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-600 group-hover:text-gray-900">{step.name}</span>
                    </span>
                  </a>
                )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
    </>
  )
}

export default function Example() {
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Cloud Development | Cloud Migration | High Quality Cloud Engineers India - C4Scale</title>
          <meta name="description" content="Cloud development &amp; Cloud developer services at C4Scale" />
        </Helmet>
      <div className="relative bg-gray-50 overflow-hidden">
        <div className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
          <div className="relative h-full max-w-7xl mx-auto">
            <svg
              className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
            </svg>
          </div>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Cloud development</span>{' '}
                <span className="block text-indigo-600 xl:inline">for start ups and all businesses</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Investing in cloud infrastructure is your business’s future. Prototyping, finding market fit and building products/services is faster in the cloud. Build your products at scale reliably & securely!!!
              We partner with enterpreneurs, businesses to bring their vision to  life !
            </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <Link
                    to="/#get-started"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white md:py-4 md:text-lg md:px-10"
                  >
                    Get Free Consultation
                </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="sectionWrapper">
        {Offerings()}
      </div>
      
      <div className="sectionWrapper">
        {Steps()}
      </div>
    
  
    </Layout>
  )
}
